import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import NavBar from "./layout/BottomNavigation";
import Profile from "./pages/Profile/Profile";
import Saved from "./pages/Saved/Saved";
import { useEffect, useState } from "react";
import { GetUserProducts } from "./services";
import {
  setIsLoading,
  setProducts,
  setSavedProducts,
} from "./store/reducers/products.reducer";
import { store } from "./store";
import axios from "axios";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { fetchUserData } from "./utils/fetchUserData";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

function App() {
  const { fetchedProducts } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.user);
  const [lastUserPreferences, setLastUserPreferences] = useState([]);
  const [isRunOutOfProducts, setIsRunOutOfProducts] = useState(false);

  const fetchNewProducts = async (shouldLoadSavedCloth = false) => {
    try {
      setLastUserPreferences(user?.preferences?.types ?? []);
      const newFetchedProducts = await GetUserProducts(user.id).then(
        ({ data }) => data,
      );
      store.dispatch(setProducts(newFetchedProducts?.new_cloths ?? []));
      if (newFetchedProducts.new_cloths_total < 50) {
        setIsRunOutOfProducts(true);
      }
      if (shouldLoadSavedCloth) {
        store.dispatch(setSavedProducts(newFetchedProducts.saved_cloths));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async () => {
    await fetchUserData();
    setLastUserPreferences(user?.preferences?.types ?? []);
    fetchNewProducts(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (
      ((fetchedProducts?.length < 10 && !isRunOutOfProducts) ||
        (user?.preferences?.types &&
          !isEqual(lastUserPreferences, user?.preferences?.types))) &&
      user?.id
    ) {
      fetchNewProducts();
    }
  }, [fetchedProducts, user]);

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path={"/home"} element={<Home />} />
        <Route path={"/profile"} element={<Profile />} />
        <Route path={"/saved"} element={<Saved />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
