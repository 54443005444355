import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { store } from "../../store";
import {
  addDislikedProducts,
  addSavedProducts,
} from "../../store/reducers/products.reducer";
import { CreateUserAction } from "../../services";
import { isArray, last } from "lodash";
import { animated, useSprings } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

const Home = () => {
  const { user } = useSelector((state) => state.user);
  const { fetchedProducts, savedProducts, dislikedProducts, isLoading } =
    useSelector((state) => state.products);
  const [props, api] = useSprings(fetchedProducts.length, () => ({
    x: 0,
    y: 0,
  }));
  const [isLoadingShop, setIsLoadingShop] = useState(false);

  useEffect(() => {
    // reset after clicking shop button
    setIsLoadingShop(false);
  }, []);

  const heartRef = useRef(null);

  const isSwiping = useRef(false);

  const bind = useDrag(
    ({
      args: [idx],
      down,
      movement: [mx, my],
      direction: [xDir],
      velocity,
    }) => {
      api.start((i) => {
        if (idx !== i) return;
        return { x: down ? mx : 0, y: down ? my : 0 };
      });

      if (velocity[0] > 0.2 && xDir > 0 && !down) {
        swipe(idx, "right");
      }
      if (velocity[0] > 0.2 && xDir < 0 && !down) {
        swipe(idx, "left");
      }
    },
  );

  const swipe = async (idx, direction) => {
    if (isSwiping.current) return;

    isSwiping.current = true;
    switch (direction) {
      case "right":
        heartRef.current.classList.add("is-active");
        setTimeout(async () => {
          if (heartRef.current?.classList?.contains("is-active")) {
            heartRef.current.classList.remove("is-active");
          }
        }, 500);
        api.start((i) => {
          if (idx !== i) return;
          return {
            x: 500,
            y: 0,
            onRest: () => {
              CreateUserAction({
                table: "Cloth",
                itemId: last(fetchedProducts).id,
                userId: user.id,
                action: "like",
              });
              store.dispatch(addSavedProducts(last(fetchedProducts)));
            },
          };
        });
        isSwiping.current = false;
        break;
      case "left":
        api.start((i) => {
          if (idx !== i) return;
          return {
            x: -500,
            y: 0,
            onRest: () => {
              CreateUserAction({
                table: "Cloth",
                itemId: last(fetchedProducts).id,
                userId: user.id,
                action: "dislike",
              });
              store.dispatch(addDislikedProducts(last(fetchedProducts)));
            },
          };
        });
        isSwiping.current = false;
        break;
    }
  };

  if (
    isLoading ||
    !(fetchedProducts && savedProducts && dislikedProducts) ||
    !isArray(fetchedProducts)
  )
    return (
      <div className={"home"}>
        <div className={"cardContainer"}>
          <Skeleton height={"100%"} />
          <div className="buttons">
            <Skeleton variant={"circular"} height={64} width={64} />
            <Skeleton variant={"circular"} height={64} width={64} />
            <Skeleton variant={"circular"} height={64} width={64} />
          </div>
        </div>
      </div>
    );

  return (
    <div className={"home"}>
      <div className={"cardContainer"}>
        <Card
          key={`card-run-out`}
          elevation={0}
          sx={{
            zIndex: -99,
            position: "absolute",
          }}
        >
          <Typography
            sx={{
              margin: 6,
              marginTop: 12,
            }}
            variant="h5"
          >
            Acabaram os Produtos de sua preferência!
            <br />
            <br />
            Volte mais tarde ou mude suas preferências.
          </Typography>
        </Card>
        {props.map(({ x, y }, index) => {
          const product = fetchedProducts[index];
          return (
            <animated.div
              {...bind(index)}
              key={`anim-key-${index}`}
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                x,
                y,
                touchAction: "none",
              }}
            >
              <Card
                key={`card-${index}`}
                elevation={0}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  position: "relative",
                  flexWrap: "wrap",
                  borderRadius: 2,
                }}
              >
                <CardMedia
                  key={`card-image-${index}`}
                  sx={{ flex: 1, objectFit: "scale-down" }}
                  image={product.imgUrl}
                />
                <CardContent className={"card"}>
                  <Typography className={"cardText"}>{product.name}</Typography>
                </CardContent>
              </Card>
            </animated.div>
          );
        })}
      </div>
      <div className="buttons">
        <IconButton onClick={() => swipe(fetchedProducts.length - 1, "left")}>
          <ClearIcon
            className={"icon"}
            style={{
              color: "#F07A55",
              borderColor: "#F07A55",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            setIsLoadingShop(true);
            setTimeout(async () => {
              setIsLoadingShop(false);
            }, 1500);
            window.open(last(fetchedProducts).link, "_self");
          }}
        >
          {isLoadingShop ? (
            <CircularProgress />
          ) : (
            <ShoppingCartIcon
              className={"icon"}
              style={{
                color: "#6BA7FF",
                borderColor: "#6BA7FF",
              }}
            />
          )}
        </IconButton>
        <IconButton onClick={() => swipe(fetchedProducts.length - 1, "right")}>
          <div className={`heartIconFrame`}>
            <div ref={heartRef} className={`heartIcon`} />
          </div>
        </IconButton>
      </div>
    </div>
  );
};

export default Home;
