import { Chip, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { UpdateUser } from "../../services";
import { store } from "../../store";
import { setUser } from "../../store/reducers/user.reducer";

const preferences = [
  { label: "Blusa", name: "blusa" },
  { label: "Calça", name: "calca" },
  { label: "Saia", name: "saia" },
  { label: "Vestido", name: "vestido" },
  { label: "Shorts", name: "shorts" },
  { label: "Bolsas", name: "bolsas" },
];

const Profile = () => {
  const { user } = useSelector((state) => state.user);
  const [preferencesTags, setPreferencesTags] = useState([]);

  useEffect(() => {
    if (user?.preferences?.types) setPreferencesTags(user?.preferences?.types);
  }, [user]);

  const UpdatePreferences = async (name) => {
    if (preferencesTags.includes(name)) {
      const newPreferences = preferencesTags.filter((p) => p !== name);
      setPreferencesTags(newPreferences);
      await UpdateUser(user.id, {
        preferences: { types: newPreferences },
      }).then(() =>
        store.dispatch(
          setUser({ ...user, preferences: { types: newPreferences } }),
        ),
      );
    } else {
      const newPreferences = [...preferencesTags, name];
      setPreferencesTags(newPreferences);
      await UpdateUser(user.id, {
        preferences: { types: newPreferences },
      }).then(() =>
        store.dispatch(
          setUser({ ...user, preferences: { types: newPreferences } }),
        ),
      );
    }
  };

  return (
    <Stack direction={"column"} spacing={4} p={2}>
      <Stack direction={"column"}>
        <Typography variant={"h4"}>Do que eu gosto?</Typography>
        <Typography variant={"subtitle2"} color={"gray"}>
          Escolha suas preferências
        </Typography>
      </Stack>
      <Stack
        direction="row"
        flexWrap={"wrap"}
        alignContent={"center"}
        justifyContent={"center"}
      >
        {preferences.map(({ label, name }) => (
          <Chip
            style={{ margin: 3 }}
            variant={preferencesTags.includes(name) ? "filled" : "outlined"}
            color={"primary"}
            icon={
              preferencesTags.includes(name) ? <CheckIcon /> : <CloseIcon />
            }
            clickable
            label={label}
            onClick={() => UpdatePreferences(name)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default Profile;
