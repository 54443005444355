import { IconButton, Paper } from "@mui/material";
import meuIdealLogo from "../assets/img/meuIdealLogoPurple.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { Link } from "react-router-dom";
import "./BottomNavigation.css";

const NavBar = () => {
  return (
    <Paper className={"navbar"} elevation={3}>
      <Link to={"saved"}>
        <IconButton>
          <FavoriteIcon className={"navBarIcon"} />
        </IconButton>
      </Link>
      <Link to={"home"}>
        <IconButton>
          <img
            src={meuIdealLogo}
            alt={"meu-ideal-logo"}
            className={"navBarIcon"}
            style={{ height: 32, width: 32 }}
          />
        </IconButton>
      </Link>
      <Link to={"profile"}>
        <IconButton>
          <AccountCircleIcon className={"navBarIcon"} />
        </IconButton>
      </Link>
    </Paper>
  );
};

export default NavBar;
