import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./reducers/products.reducer";
import profileReducer from "./reducers/user.reducer";

export const store = configureStore({
  reducer: {
    products: productsReducer.reducer,
    user: profileReducer.reducer,
  },
  devTools: true,
});
