import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "./Saved.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { store } from "../../store";
import { removeSavedProducts } from "../../store/reducers/products.reducer";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { DeleteUserAction } from "../../services";

const Profile = () => {
  const { savedProducts } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.user);

  if (!savedProducts) {
    return <CircularProgress className={"loading"} size={""} />;
  }

  return (
    <Stack
      direction={"column"}
      className={"savedContainer"}
      height={"90%"}
      justifyContent={"flex-start"}
      overflow={"scroll"}
    >
      <Typography variant={"h4"} marginTop={2}>
        Salvos
      </Typography>
      <Typography variant={"subtitle2"} color={"gray"}>
        Clique para ir a loja
      </Typography>
      <Stack
        width={"100%"}
        height={"100%"}
        direction="row"
        flexWrap={"wrap"}
        alignContent={"flex-start"}
        justifyContent={"center"}
      >
        {savedProducts?.length === 0 ? (
          <Stack
            direction={"column"}
            spacing={6}
            marginTop={12}
            width={"50%"}
            minWidth={300}
          >
            <Typography variant={"h6"}>
              Nada por aqui, clique aqui para explorar alguns produtos
            </Typography>
            <Link to={"home"}>
              <IconButton>
                <SearchIcon className={"exploreIcon"} />
              </IconButton>
            </Link>
          </Stack>
        ) : (
          savedProducts.map((product, idx) => (
            <Card
              key={idx}
              elevation={1}
              sx={{
                width: 96,
                height: 128,
                position: "relative",
                margin: 1,
              }}
              onClick={() => window.open(product.link, "_self")}
            >
              <IconButton
                style={{ position: "absolute", top: 1, left: 1, zIndex: 9999 }}
                onClick={async (e) => {
                  e.stopPropagation();
                  store.dispatch(removeSavedProducts(product));
                  await DeleteUserAction(user.id, product.id);
                }}
              >
                <DeleteIcon
                  style={{
                    color: "#fff",
                    backgroundColor: "#8169ff",
                    borderColor: "#8169ff",
                    borderRadius: 32,
                    fontSize: 16,
                    padding: 2,
                  }}
                />
              </IconButton>
              <CardMedia sx={{ height: "100%" }} image={product.imgUrl} />
              <CardContent className={"savedCard"}>
                <Typography className={"savedCardText"} sx={{ height: 32 }}>
                  {product.name}
                </Typography>
              </CardContent>
            </Card>
          ))
        )}
      </Stack>
    </Stack>
  );
};

export default Profile;
