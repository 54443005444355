import axios from "axios";
import { fetchUserData } from "../utils/fetchUserData";

export const idealBackendUrl = process.env.REACT_APP_IDEAL_BACKEND_URL;

axios.defaults.baseURL = idealBackendUrl;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      await fetchUserData();
    }
    return error;
  },
);

export const GetUserProducts = async (userId) =>
  await axios.get(`/cloth?userId=${userId}`);

export const CreateUserAction = async (data) =>
  await axios.post(`/users/actions`, data);

export const DeleteUserAction = async (userId, id) =>
  await axios.delete(`/users/actions/${userId}/${id}`);

export const CreateAnonymousUser = async () => await axios.post(`/users`, {});

export const UpdateUser = async (userId, data) =>
  await axios.patch(`/users/${userId}`, data);

export const GetUser = async (userId) => await axios.get(`/users/${userId}`);

export const AnonymousLogin = async (userId) =>
  await axios.post(`/login`, { anonymous: true, userId });
//
// export const Login = async (userId) =>
//   await axios.post(`/login/${userId}`);
