import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";

const initialState = {
  fetchedProducts: [],
  page: 0,
  savedProducts: [],
  dislikedProducts: [],
  isLoading: true,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.fetchedProducts = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSavedProducts: (state, action) => {
      state.savedProducts = action.payload;
    },
    addSavedProducts: (state, action) => {
      const product = action.payload;
      if (findIndex(state.savedProducts, (e) => e.id === product.id) === -1) {
        state.savedProducts.push(product);
      }
      state.fetchedProducts.pop();
    },
    removeSavedProducts: (state, action) => {
      state.savedProducts = state.savedProducts.filter(
        (e) => e.id !== action.payload.id,
      );
    },
    addDislikedProducts: (state, action) => {
      const product = action.payload;
      state.dislikedProducts.push(product);
      state.fetchedProducts.pop();
    },
    clear: () => {
      initialState;
    },
  },
});

const {
  setIsLoading,
  setProducts,
  addDislikedProducts,
  removeSavedProducts,
  addSavedProducts,
  setSavedProducts,
  clear,
} = productsSlice.actions;

export default productsSlice;
export {
  setIsLoading,
  setProducts,
  addDislikedProducts,
  removeSavedProducts,
  addSavedProducts,
  setSavedProducts,
  clear,
};
