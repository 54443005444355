import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clear: () => {
      initialState;
    },
  },
});

const { setUser, clear } = userSlice.actions;

export default userSlice;
export { setUser, clear };
